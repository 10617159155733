import React from "react"
import { PDFViewer } from "@react-pdf/renderer"
import Report from "../components/test/question/components/final/components/report"

const PdfReportPage = ({ location }) => {
  const { state } = location

  const {
    majorLevel,
    minorLevel,
    name,
    subjectLevels,
    languageTested,
    languageLevel,
    resultObject,
    duration,
    finishedTimestamp,
    languagePercentage,
  } = state

  return (
    <div>
      <PDFViewer style={{ width: "100%", height: "100vh" }}>
        <Report
          majorLevel={majorLevel}
          minorLevel={minorLevel}
          name={name}
          languageTested={languageTested}
          languageLevel={languageLevel}
          duration={duration}
          finishedTimestamp={finishedTimestamp}
          subjectLevels={subjectLevels}
          languagePercentage={languagePercentage}
          resultObject={resultObject}
        />
      </PDFViewer>
    </div>
  )
}

export default PdfReportPage
