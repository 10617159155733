import React from "react"
import { Page, Text, View, Document, Image, Font, Link } from "@react-pdf/renderer"
import messages from "../../messages"
import { getLanguageName } from "../../../../../../../utils/language"
import logo from "../../../../../../../images/logos/logo.png"
import moment from "moment"
import LevelScalePdf from "./levelScalePdf"
import OpenSans from "../../../../../../../../fonts/OpenSans-Regular.ttf"
import OpenSansMedium from "../../../../../../../../fonts/OpenSans-SemiBold.ttf"
import OpenSansBold from "../../../../../../../../fonts/OpenSans-Bold.ttf"

import useFormatMessageForLocale from "../../../../../../../utils/hooks/useFormatMessageForLocale"

Font.register({ family: "Regular", src: OpenSans })
Font.register({ family: "Medium", src: OpenSansMedium })
Font.register({ family: "Bold", src: OpenSansBold })

const Report = ({
  majorLevel,
  minorLevel,
  name,
  languageTested,
  languageLevel,
  duration,
  finishedTimestamp,
  subjectLevels,
  languagePercentage,
  resultObject,
}) => {
  const formatMessage = useFormatMessageForLocale(languageTested)
  const result = resultObject

  const formatLevelMessage = (message, pi = "", majLevel, minLevel) => {
    if (majLevel) {
      if (majLevel === "A0" && (minLevel === "50" || minLevel === "75")) {
        return formatMessage(messages[`A050${message}${pi}`])
      }
      return formatMessage(messages[`${majLevel}${message}${pi}`])
    }

    if (majorLevel === "A0" && minorLevel === "(50)" && `A050${message}${pi}` in messages) {
      return formatMessage(messages[`A050${message}${pi}`])
    }

    if (majorLevel === "A0" && minorLevel === "(75)" && `A075${message}${pi}` in messages) {
      return formatMessage(messages[`A075${message}${pi}`])
    }

    if (majorLevel === "A0" && minorLevel === "(75)" && `A050${message}${pi}` in messages) {
      return formatMessage(messages[`A050${message}${pi}`])
    }

    return formatMessage(messages[`${majorLevel}${message}${pi}`])
  }

  return (
    <Document>
      <Page
        style={{
          fontFamily: "Regular",
        }}
      >
        <View style={{ backgroundColor: "#77B127", height: 4, width: "100%", borderRadius: 4 }} />
        <View style={{ paddingTop: 24, paddingBottom: 24, paddingRight: 48, paddingLeft: 48 }}>
          <View style={{ display: "flex", flexDirection: "row", marginBottom: 16 }}>
            <View style={{ display: "flex", flexDirection: "column", widht: "100%" }}>
              <Image src={logo} style={{ padding: 0, marginLeft: -8, marginTop: -12, width: 72 }} />
              <View style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Text style={{ fontSize: 30, fontFamily: "Bold", color: "#2d3748" }}>{majorLevel}</Text>
                <Text style={{ fontSize: 24, fontFamily: "Bold", color: "#718096", marginTop: 4 }}>_{minorLevel.replace(/[{()}]/g, "")}</Text>
              </View>
              <Text style={{ fontFamily: "Medium", color: "#a0aec0" }}>{formatLevelMessage("Description")}</Text>
            </View>
            <View style={{ display: "flex", flex: "1 1 0%", flexGrow: 1, justifyContent: "center" }} />
            <View style={{ display: "flex", flexDirection: "column" }}>
              <Text style={{ fontSize: 20, fontFamily: "Bold", color: "#2d3748" }}>{name}</Text>
              <Text style={{ fontSize: 14, fontFamily: "Medium", color: "#718096" }}>{getLanguageName(languageTested)}</Text>
              <Text style={{ fontSize: 14, fontFamily: "Medium", color: "#a0aec0" }}>{moment(finishedTimestamp).format("LL")}</Text>
            </View>
          </View>
          <View style={{ fontSize: 14, fontFamily: "Medium", color: "#718096", width: "100%", marginBottom: 4 }} />
          <Text style={{ fontSize: 18, fontFamily: "Bold", color: "#718096", width: "100%", textAlign: "left" }}>
            {formatMessage(messages.MyScore)}
          </Text>
          {/* TODO: LEVEL SCALE INSERT HERE */}
          <LevelScalePdf currentLevel={languageLevel} majorLevel={majorLevel} minorLevel={minorLevel} languagePercentage={languagePercentage} />
          {/* GENERAL */}
          {!!subjectLevels.sectionGENE && (
            <>
              <Text
                style={{
                  fontSize: 16,
                  fontFamily: "Medium",
                  color: "#2d3748",
                  width: "100%",
                  marginBottom: 8,
                  marginTop: 40,
                }}
              >
                {formatMessage(messages.Description)} {subjectLevels.sectionGENE.majorLevel}
                <Text style={{ fontFamily: "Medium", color: "#a0aec0" }}>_{subjectLevels.sectionGENE.minorLevel}</Text>
              </Text>
              <Text
                style={{
                  marginLeft: 12,
                  fontSize: 12,
                  fontFamily: "Medium",
                  color: "#4a5568",
                  width: "100%",
                  marginBottom: 4,
                }}
              >
                {formatMessage(messages.Positives)}
              </Text>
              <Text style={{ marginLeft: 12, fontSize: 11, color: "#2d3748", marginBottom: 8 }}>
                {formatLevelMessage("Description", "Pos", subjectLevels.sectionGENE.majorLevel, subjectLevels.sectionGENE.minorLevel)}
              </Text>
              <Text
                style={{
                  marginLeft: 12,
                  fontSize: 12,
                  fontFamily: "Medium",
                  color: "#4a5568",
                  width: "100%",
                  marginBottom: 4,
                }}
              >
                {formatMessage(messages.Improvements)}
              </Text>
              <Text style={{ marginLeft: 12, fontSize: 11, color: "#2d3748" }}>
                {formatLevelMessage("Description", "Imp", subjectLevels.sectionGENE.majorLevel, subjectLevels.sectionGENE.minorLevel)}
              </Text>
            </>
          )}
          {/* PROFESSIONAL */}
          {!!subjectLevels.sectionBUSI && (
            <>
              <Text
                style={{
                  fontSize: 16,
                  fontFamily: "Medium",
                  color: "#2d3748",
                  width: "100%",
                  marginBottom: 8,
                  marginTop: 16,
                }}
              >
                {formatMessage(messages.Professional)} {subjectLevels.sectionBUSI.majorLevel}
                <Text style={{ fontFamily: "Medium", color: "#a0aec0" }}>_{subjectLevels.sectionBUSI.minorLevel}</Text>
              </Text>
              <Text
                style={{
                  marginLeft: 12,
                  fontSize: 12,
                  fontFamily: "Medium",
                  color: "#4a5568",
                  width: "100%",
                  marginBottom: 4,
                }}
              >
                {formatMessage(messages.Positives)}
              </Text>
              <Text style={{ marginLeft: 12, fontSize: 11, color: "#2d3748", marginBottom: 8 }}>
                {formatLevelMessage("Professional", "Pos", subjectLevels.sectionBUSI.majorLevel, subjectLevels.sectionBUSI.minorLevel)}
              </Text>
              <Text
                style={{
                  marginLeft: 12,
                  fontSize: 12,
                  fontFamily: "Medium",
                  color: "#4a5568",
                  width: "100%",
                  marginBottom: 4,
                }}
              >
                {formatMessage(messages.Improvements)}
              </Text>
              <Text style={{ marginLeft: 12, fontSize: 11, color: "#2d3748", marginBottom: 8 }}>
                {formatLevelMessage("Professional", "Imp", subjectLevels.sectionGENE.majorLevel, subjectLevels.sectionBUSI.minorLevel)}
              </Text>
            </>
          )}
          {/* STRUCTURE */}
          {!!subjectLevels.sectionSTRU && (
            <>
              <Text
                style={{
                  fontSize: 16,
                  fontFamily: "Medium",
                  color: "#2d3748",
                  width: "100%",
                  marginBottom: 8,
                  marginTop: 16,
                }}
              >
                {formatMessage(messages.StructureTitle)} {subjectLevels.sectionSTRU.majorLevel}
                <Text style={{ fontFamily: "Medium", color: "#a0aec0" }}>_{subjectLevels.sectionSTRU.minorLevel}</Text>
              </Text>
              <Text
                style={{
                  marginLeft: 12,
                  fontSize: 12,
                  fontFamily: "Medium",
                  color: "#4a5568",
                  width: "100%",
                  marginBottom: 4,
                }}
              >
                {formatMessage(messages.Structure)}
              </Text>

              <Text style={{ marginLeft: 12, fontSize: 11, color: "#2d3748", marginBottom: 4 }}>{result?.recommendationsstru} </Text>
            </>
          )}
        </View>

        <View style={{ marginLeft: 24, marginRight: 24, display: "flex", flexDirection: "row", alignItems: "center", marginTop: 12 }}>
          <View style={{ display: "flex", flex: "1 1 0%", flexDirection: "column", flexGrow: 1 }} />
          <View style={{ fontSize: 11, fontFamily: "Regular", color: "#a0aec0" }}>
            <Link src="https://www.atolo.eu">https://www.atolo.eu</Link>
            <Link src="https://www.atolo.ch">https://www.atolo.ch</Link>
          </View>
        </View>
      </Page>
    </Document>
  )
}

export default Report
