import React from "react"
import { Text, View } from "@react-pdf/renderer"
import { levels } from "../utils/levels"
import _ from "lodash"

const LevelScalePdf = ({ currentLevel, minorLevel, majorLevel, languagePercentage }) => {
  const nbOfLevels = levels.length
  const percentage = levels.indexOf(currentLevel) / nbOfLevels
  return (
    <View style={{ width: "100%", display: "flex", marginTop: 32 }}>
      <View style={{ display: "flex", widht: "100%", justifyContent: "space-between" }}>
        <View style={{ height: 8, backgroundColor: "#e2e8f0", borderRadius: 8, width: "100%", position: "relative" }}>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: "absolute",
              fontSize: 12,
              color: "#2d3748",
              marginTop: -48,
              marginLeft: -32,
              left: `${Math.min(percentage + 0.01, 1) * 100}%`,
              zIndex: -10,
            }}
          >
            <Text style={{ marginTop: 16, fontFamily: "Bold" }}>{`${majorLevel}_${minorLevel.replace(/[{()}]/g, "")} (${languagePercentage.toFixed(
              2
            )}%)`}</Text>
          </View>
          <View style={{ backgroundColor: "#77B127", height: 8, borderRadius: 8, width: `${Math.min(percentage + 0.01, 1) * 100}%` }} />
          {["A0 (0)", "A1 (0)", "A2 (0)", "B1 (0)", "B2 (0)", "C1 (0)", "C2 (0)"].map(l => (
            <View
              key={l}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                position: "absolute",
                fontSize: 12,
                color: "#a0aec0",
                marginTop: 10,
                marginLeft: -8,
                left: `${Math.min(levels.indexOf(l) / nbOfLevels + 0.01, 1) * 100}%`,
                zIndex: -10,
              }}
            >
              <View style={{ backgroundColor: "#cbd5e0", marginTop: 4, height: 10, width: 2 }}></View>
              <Text>{_.first(l.split(" "))}</Text>
            </View>
          ))}
        </View>
      </View>
    </View>
  )
}
export default LevelScalePdf
